@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    margin: 0px;
    padding: 0px;
}

body {
    font-family: 'Inter', sans-serif !important;
    overflow-x: hidden;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    background-color: #fffee1 !important;
}

a, a:hover {
    text-decoration: none !important;
}

button {
    border-radius: 0px;
    border: inherit;
    outline: none;
}

    button:focus {
        border-radius: 0px;
        border: inherit;
        outline: none;
        box-shadow: none;
    }

ul {
    margin: 0px;
    padding: 0px;
}

    ul li {
        list-style: none;
    }

input:focus {
    border: none;
    outline: none;
    box-shadow: none;
}

p {
    color: #797979;
    text-align: justify;
}


:root {
    --yellow: #ed1c24;
    --blue: #000000;
}

h1 {
    font-size: 46px;
    font-weight: 800;
}

h2 {
    font-size: 40px;
    font-weight: 900;
    position: relative;
    display: inline-block;
}

    h2 span {
        color: var(--yellow);
    }

    h2:before {
        position: absolute;
        content: "";
        left: 0px;
        bottom: -10px;
        background-color: #edc003;
        width: 150px;
        height: 3px;
    }

h3 {
    font-size: 36px;
    font-weight: 900;
    position: relative;
    display: inline-block;
}

    h3:before {
        position: absolute;
        content: "";
        left: 50%;
        bottom: -10px;
        background-color: #edc003;
        width: 150px;
        height: 3px;
        transform: translate(-50%, -50%);
    }


    h3 span {
        color: var(--yellow);
    }

h4 {
    font-size: 18px;
    font-weight: 600;
}


/*back to top css start*/


#myBtn {
    display: inline-block;
    background-color: #fff;
    text-align: center;
    position: fixed;
    bottom: 30px;
    right: 30px;
    transition: background-color .3s, opacity .5s, visibility .5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
    width: 44px;
    height: 44px;
    line-height: 44px;
    -webkit-animation: backto-top-bounce 4s infinite ease-in-out;
    animation: backto-top-bounce 4s infinite ease-in-out;
    box-shadow: 0 0 10px 7px rgb(0 0 0 / 3%);
    cursor: pointer;
}

@-webkit-keyframes backto-top-bounce {
    0% {
        -webkit-transform: translateY(-4px);
        transform: translateY(-5px);
    }

    50% {
        -webkit-transform: translateY(8px);
        transform: translateY(10px);
    }

    100% {
        -webkit-transform: translateY(-4px);
        transform: translateY(-5px);
    }
}

@keyframes backto-top-bounce {
    0% {
        -webkit-transform: translateY(-4px);
        transform: translateY(-5px);
    }

    50% {
        -webkit-transform: translateY(8px);
        transform: translateY(10px);
    }

    100% {
        -webkit-transform: translateY(-4px);
        transform: translateY(-5px);
    }
}


#myBtn i {
    font-size: 20px;
    color: var(--blue);
}

#myBtn:active {
    background-color: #555;
}

#myBtn.show {
    opacity: 1;
    visibility: visible;
}

/*back to top css end*/

/*top-header css start*/

.top-header {
    background: #5041fa;
    padding: .5rem;
}

.top-left-header {
}

    .top-left-header ul {
        display: flex;
        align-items: center;
        margin-bottom: 0px;
        padding-left: 0px;
    }

        .top-left-header ul li {
            list-style: none;
            display: flex;
            align-items: center;
            margin-right: 20px;
        }

            .top-left-header ul li i {
                color: #fff;
                margin-right: 10px;
            }

            .top-left-header ul li p {
                margin-bottom: 0px;
                color: #fff;
                font-size: 14px;
            }

            .top-left-header ul li div {
            }

                .top-left-header ul li div a {
                    text-decoration: none;
                    color: #fff;
                    font-size: 14px;
                }

                    .top-left-header ul li div a:hover {
                        color: #fff;
                    }

.top-right-header {
    text-align: right;
}

    .top-right-header ul {
        margin-bottom: 0px;
        padding-left: 0px;
    }

        .top-right-header ul li {
            list-style: none;
            display: inline-block;
        }

            .top-right-header ul li a {
                width: 25px;
                display: block;
                color: var(--purple);
            }

                .top-right-header ul li a i {
                    color: #fff;
                }

/*top-header css end*/

/*nav css start*/

.menu {
    transition: top 0.8s;
    background-color: #dceaf3;
    padding: 15px 0px;
}
#txtPickupPincode{
    width : 100% !important;
}

.menu-button a {
    width: fit-content;
    padding: 10px 15px;
    display: block;
    text-align: center;
}

.menu-button {
    display: flex;
    justify-content: end;
}

    .menu-button .button-box {
        margin-right: 15px;
    }

        .menu-button .button-box:last-child {
            margin-right: 0px;
        }

.header-button {
    border-radius: 5px;
    background-color: var(--yellow) ;
    color: #fff;
    padding: 12px 0px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
}
#btnGet{
    background-color:var(--yellow)!important;
}
.header-button:hover {
    background-color: var(--yellow);
    color: #fff;
}

.header-button2 {
    background-color: var(--blue);
    color: #fff;
}

.header-button3 {
    background-color: #4e41ff;
    color: #fff;
}

.header-button2:hover {
    background-color: var(--blue);
    color: #fff;
}

.header-button3:hover {
    background-color: #4e41ff;
    color: #fff;
}

.header-button span {
    position: relative;
    transition: 400ms;
}

    .header-button span:after {
        content: '\00bb';
        position: absolute;
        opacity: 0;
        top: -7px;
        right: -20px;
        transition: 500ms;
        font-size: 20px;
    }

.header-button:hover span {
    padding-right: 25px;
}

    .header-button:hover span:after {
        opacity: 1;
        right: 0px;
    }

.fd-nav {
    background-color: #dceaf3;
    box-shadow: 0px -4px 10px rgb(0 0 0 / 15%);
}


    .fd-nav nav ul li a {
        color: #000 !important;
        font-size: 16px;
    }

    .fd-nav nav ul li .dropdown-menu {
        padding-top: 30px;
    }


        .fd-nav nav ul li .dropdown-menu li:first-child {
            border-top-right-radius: 0px;
            border-top-left-radius: 0px;
        }

/*nav css end*/

/*banner css start*/

.home-banner .item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.home-banner .item .cover {
    padding: 75px 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(0 0 0 / 25%);
    display: flex;
    align-items: center;
}

    .home-banner .item .cover .header-content {
        position: relative;
        padding: 56px;
        overflow: hidden;
    }

        .home-banner .item .cover .header-content .line {
            content: "";
            display: inline-block;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            position: absolute;
            border: 9px solid #fff;
            -webkit-clip-path: polygon(0 0, 60% 0, 36% 100%, 0 100%);
            clip-path: polygon(0 0, 60% 0, 36% 100%, 0 100%);
        }

        .home-banner .item .cover .header-content h2 {
            font-weight: 300;
            font-size: 35px;
            color: #fff;
        }

        .home-banner .item .cover .header-content h1 {
            font-size: 56px;
            font-weight: 600;
            margin: 5px 0 20px;
            word-spacing: 3px;
            color: #fff;
        }

        .home-banner .item .cover .header-content h4 {
            font-size: 24px;
            font-weight: 300;
            line-height: 36px;
            color: #fff;
        }

.home-banner .owl-item.active h1 {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: fadeInDown;
    animation-delay: 0.3s;
}

.home-banner .owl-item.active h2 {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: fadeInDown;
    animation-delay: 0.3s;
}

.home-banner .owl-item.active h4 {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: fadeInUp;
    animation-delay: 0.3s;
}

.home-banner .owl-item.active .line {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: fadeInLeft;
    animation-delay: 0.3s;
}

.home-banner .owl-nav .owl-prev {
    position: absolute;
    left: 15px;
    top: 43%;
    opacity: 0;
    -webkit-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    background: rgba(0, 0, 0, 0.5) !important;
    width: 40px;
    cursor: pointer;
    height: 40px;
    position: absolute;
    display: block;
    z-index: 1000;
    border-radius: 0;
}

    .home-banner .owl-nav .owl-prev span {
        font-size: 1.6875rem;
        color: #fff;
    }

    .home-banner .owl-nav .owl-prev:focus {
        outline: 0;
    }

    .home-banner .owl-nav .owl-prev:hover {
        background: #000 !important;
    }

.home-banner .owl-nav .owl-next {
    position: absolute;
    right: 15px;
    top: 43%;
    opacity: 0;
    -webkit-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    background: rgba(0, 0, 0, 0.5) !important;
    width: 40px;
    cursor: pointer;
    height: 40px;
    position: absolute;
    display: block;
    z-index: 1000;
    border-radius: 0;
}

    .home-banner .owl-nav .owl-next span {
        font-size: 1.6875rem;
        color: #fff;
    }

    .home-banner .owl-nav .owl-next:focus {
        outline: 0;
    }

    .home-banner .owl-nav .owl-next:hover {
        background: #000 !important;
    }

.home-banner:hover .owl-prev {
    left: 0px;
    opacity: 1;
}

.home-banner:hover .owl-next {
    right: 0px;
    opacity: 1;
}


/*banner css end*/

/*track-now css start*/

.home-banner .tracking-app-banner {
    margin-top: 80px;
}

.tracking-app-banner .tracking-image img {
    height: 229px;
    width: 100%;
}

.tracking-app-banner .menu-button {
    justify-content: start;
}

.tracking-app-banner .tracking-image img {
    border-radius: 10px;
}


.tracking-app-banner .tracking-bx {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    position: relative;
    border: 2px solid #f6263b;
}

    .tracking-app-banner .tracking-bx:after {
      
      
        background-image: url(../public/images/tl-track.png);

        background-repeat: no-repeat;
        background-size: 250px;
        content: "";
        height: 87px;
        left: 50%;
        position: absolute;
        display: inline;
        top: -7px;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 250px;
    }

.tl-track

.tracking-app-banner .tracking-bx:after {
    background: url(../public/images/track-box-shadow.png);
    content: "";
    width: 100%;
    height: 80px;
    position: absolute;
    top: 100%;
    left: 0;
    background-size: 100%;
    background-repeat: no-repeat;
}

.tracking-app-banner .tracking-bx .track-by-check {
    display: flex;
    align-items: center;
}

.tracking-app-banner .tracking-bx .form-check {
    padding-left: 0px;
}

.tracking-app-banner .tracking-bx .track-by-check label {
    font-size: 18px;
    color: #000;
    font-weight: 600;
}

.tracking-app-banner .tracking-bx .track-by-check input {
    margin-top: 0px;
    margin-right: 5px;
}

.tracking-app-banner .tracking-bx .track-by-check .form-check {
    display: flex;
    align-items: center;
}


.tracking-app-banner .track-input-field {
    margin-top: 20px;
    display: flex;
    align-items: center;
}

    .tracking-app-banner .track-input-field .fields input {
        background-color: initial;
        border: 0;
        border-bottom: 1px solid #e9e9e9;
        border-radius: 0;
        margin-bottom: -10px;
        padding-bottom: 0px !important;
        padding-left: 35px !important;
    }

.tracking-app-banner .valid-awb-no {
    margin-top: 5px;
}

    .tracking-app-banner .valid-awb-no span {
        color: #a2a2a2;
        font-size: 12px;
        display: block;
        line-height: 18px;
    }

.tracking-app-banner .track-input-field .fields {
    position: relative;
    width: 100%;
}

.tracking-app-banner .track-input-field .input-image {
    position: absolute;
}

    .tracking-app-banner .track-input-field .input-image img {
        width: 25px;
    }

.tracking-app-banner .track-input-field input:focus {
    box-shadow: none;
}

.tracking-app-banner .track-input-field .fields .bar {
    display: block;
    position: relative;
    width: 50%;
}

    .tracking-app-banner .track-input-field .fields .bar:before,
    .tracking-app-banner .track-input-field .fields .bar:after {
        content: "";
        /*height: 1px;*/
        width: 0;
        bottom: 0px;
        position: absolute;
        background: var(--blue);
        transition: all 0.2s ease;
    }

    .tracking-app-banner .track-input-field .fields .bar:before {
        left: 100%;
    }

    .tracking-app-banner .track-input-field .fields .bar:after {
        right: 0px;
    }

.tracking-app-banner .track-input-field .fields input:focus ~ .bar:before,
.tracking-app-banner .track-input-field .fields input:focus ~ .bar:after {
    width: 100%;
}

/*track-now css end*/


/*about-us section css start*/
.about-us {
    margin-top: 80px;
}

    .about-us .about-image img {
        border-radius: 10px;
    }

    .about-us .delivery-bar {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 30px;
    }

        .about-us .delivery-bar div {
            margin-right: 20px;
        }

            .about-us .delivery-bar div:last-child {
                margin-right: 0px;
            }

        .about-us .delivery-bar h4 {
            text-align: center;
            font-weight: 700;
            margin-top: 20px;
        }

svg.radial-progress {
    height: auto;
    max-width: 180px;
    padding: 0;
    transform: rotate(-90deg);
    width: 100%;
    border-radius: 50%;
}


    svg.radial-progress circle {
        fill: transparent;
        stroke: #fff;
    }

        svg.radial-progress circle.bar-static {
            stroke: #F5F5F5 !important;
            stroke-width: 5px;
        }

        svg.radial-progress circle.bar--animated {
            stroke-dasharray: 219.91148575129;
            stroke: var(--yellow);
            stroke-dashoffset: 219.91148575129;
            stroke-width: 5px;
        }

    svg.radial-progress text {
        fill: var(--blue);
        font-size: 16px;
        text-anchor: middle;
        font-weight: 800;
    }

/*about-us section css end*/

/*our-services css start*/

.our-services {
    margin-top: 80px;
    background-image: url(../public/images/service-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 80px 0px;
}

    .our-services h3 {
        color: #fff;
    }

        .our-services h3 span {
            color: var(--yellow);
        }

    .our-services h4 {
        color: #e9e9e9;
    }

    .our-services .service-box {
        position: relative;
    }

        .our-services .service-box .service-image {
            height: 280px;
            width: 100%;
            position: relative;
            overflow: hidden;
        }

            .our-services .service-box .service-image img {
                object-fit: cover;
                height: 100%;
                width: 100%;
                transition: 0.5s;
            }

        .our-services .service-box .service-content {
            position: absolute;
            content: "";
            height: 100%;
            width: 100%;
            background-color: #0000006b;
            top: 0px;
            left: 0px;
            padding: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            transition: 0.5s;
        }

        .our-services .service-box:hover .service-content {
            opacity: 1;
        }

        .our-services .service-box:hover .service-image img {
            transform: scale(1.2);
        }

        .our-services .service-box .service-content {
            text-align: center;
        }

            .our-services .service-box .service-content h4 {
                color: #fff;
                font-size: 24px;
                font-weight: 500;
            }

            .our-services .service-box .service-content p {
                color: #fff;
                text-align: center;
            }

        .our-services .service-box .menu-button {
            justify-content: center;
        }

    .our-services .services {
        margin-top: 30px;
    }

/*our-services css end*/

/*our-advantage css start*/

.our-advantage {
    padding: 80px 0px;
    position: relative;
}

    .our-advantage h3:before {
        left: 0px;
        transform: inherit;
    }

    .our-advantage:after {
        position: absolute;
        content: "";
        background-image: url(../public/images/advantage-after.png);
        right: 0px;
        bottom: 0px;
        width: 400px;
        height: 380px;
        background-size: 400px;
        background-repeat: no-repeat;
        z-index: -1;
    }

    .our-advantage .advantage-box {
        background-color: #fff;
        padding: 30px;
        box-shadow: 0 0 10px 7px rgb(0 0 0 / 3%);
        border-radius: 10px;
    }

    .our-advantage .advantage-image {
        padding-right: 30px;
    }

        .our-advantage .advantage-image img {
            border-radius: 5px;
        }

    .our-advantage .advantage-box .advantage-content ul li {
        display: flex;
        align-items: baseline;
        margin-bottom: 10px;
    }

        .our-advantage .advantage-box .advantage-content ul li:last-child {
            margin-bottom: 0px;
        }

        .our-advantage .advantage-box .advantage-content ul li i {
            color: var(--blue);
            margin-right: 8px;
            font-size: 18px;
        }

        .our-advantage .advantage-box .advantage-content ul li span {
            color: #000;
            font-size: 16px;
            font-weight: 600;
        }

/*our-advantage css end*/

/*our-activities css start*/

.our-activities {
    padding: 80px 0px;
    background-image: url(../public/images/activities-bg.jpg);
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
}

    .our-activities h3:before {
        left: 0px;
        transform: inherit;
    }

    .our-activities .activities-pointer ul li {
        display: flex;
        align-items: baseline;
        margin-bottom: 10px;
    }

        .our-activities .activities-pointer ul li i {
            color: var(--blue);
            margin-right: 8px;
            font-size: 18px;
        }

        .our-activities .activities-pointer ul li span {
            color: #000;
            font-size: 16px;
            font-weight: 600;
        }

    .our-activities .activities-content {
        background-color: #fff;
        padding: 30px;
        border-radius: 5px;
    }

    .our-activities .activities-img img {
        border-radius: 5px;
    }

/*our-activities css end*/

/*why-choose css start*/

.why-choose {
    margin-top: 80px;
}

    .why-choose .industry-sec {
        display: flex;
        align-items: center;
        box-shadow: 0 0 10px 7px rgb(0 0 0 / 3%);
        padding: 15px;
        border-radius: 5px;
    }

        .why-choose .industry-sec h4 {
            margin-bottom: 0px;
        }

        .why-choose .industry-sec .industry-icon {
            margin-right: 15px;
            background-color: #edc003;
            color: #fff;
            font-size: 24px;
            height: 50px;
            width: 50px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: none;
        }

.contact-number a {
    color: var(--yellow);
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
}

.contact-number h4 {
    font-size: 16px;
}
/*why-choose css end*/

/*faq css start*/

.faq {
    margin-top: 80px;
}

    .faq .menu-button {
        justify-content: start;
    }

    .faq h3:before {
        left: 0px;
        transform: inherit;
    }

    .faq .choose-pointer ul li {
        display: flex;
        align-items: baseline;
        margin-bottom: 5px;
    }

        .faq .choose-pointer ul li i {
            color: var(--blue);
            margin-right: 8px;
            font-size: 18px;
        }

        .faq .choose-pointer ul li span {
            color: #000;
            font-size: 16px;
            font-weight: 600;
        }

    .faq .contact-form {
        padding: 30px;
        background-color: #fff;
        margin-left: 20px;
        box-shadow: 0 0 10px 7px rgb(0 0 0 / 3%);
    }

.fields label {
    color: #000;
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 14px;
    width: 100%;
}

    .fields label span {
        color: red;
    }

.fields input {
    margin-bottom: 0px;
    padding: 1rem .75rem;
}

    .fields input:focus {
        box-shadow: none;
        box-shadow: inset 0 0 0 1px #f0f3f5, inset 2px 2px 4px rgba(0,0,0,.2);
        border: 1px solid transparent;
    }

.fields select {
    margin-bottom: 15px;
}

    .fields select:focus {
        box-shadow: none;
        box-shadow: inset 0 0 0 1px #f0f3f5, inset 2px 2px 4px rgba(0,0,0,.2);
        border: 1px solid transparent;
    }

.fields textarea {
    margin-bottom: 15px;
    /*min-height: 140px;*/
    max-height: 140px;
    border-color:var(--bg-light);
}

    .fields textarea:focus {
        box-shadow: none;
        box-shadow: inset 0 0 0 1px #f0f3f5, inset 2px 2px 4px rgba(0,0,0,.2);
        border: 1px solid transparent;
    }

.fields .selct-input {
    display: inline-flex;
    width: 100%;
}

    .fields .selct-input input {
        margin-right: 15px;
    }

/*faq css end*/

/*airliness-partner css start*/

.airliness-partner {
    margin-top: 80px;
    margin-bottom: 80px;
}

    .airliness-partner .air-partner {
        background-color: #e5e5e5;
        padding: 20px;
    }

        .airliness-partner .air-partner .partner-image {
            width: 180px;
            height: 60px;
            /*  background-image: url('../public/images/our-partner/partner1.png');*/
            background-size: 150px;
            background-position: 0% 100%;
            overflow: hidden;
            margin: auto auto;
            transition: 0.2s;
            background-repeat: no-repeat;
        }

/*  .airliness-partner .air-partner .air2 {
            background-image: url('../public/images/our-partner/partner2.png');
        }

        .airliness-partner .air-partner .air3 {
            background-image: url('../public/images/our-partner/partner3.png');
        }

        .airliness-partner .air-partner .air4 {
            background-image: url('../public/images/our-partner/partner4.png');
        }

        .airliness-partner .air-partner .air5 {
            background-image: url('../public/images/our-partner/partner5.png');
        }

        .airliness-partner .air-partner .air6 {
            background-image: url('../public/images/our-partner/partner6.png');
        }

        .airliness-partner .air-partner .air7 {
            background-image: url('../public/images/our-partner/partner7.png');
        }

        .airliness-partner .air-partner .air8 {
            background-image: url('../public/images/our-partner/partner8.png');
        }

        .airliness-partner .air-partner .air9 {
            background-image: url('../public/images/our-partner/partner9.png');
        }

        .airliness-partner .air-partner:hover .partner-image {
            background-position: 0% 0%;
        }*/

/*airliness-partner css end*/


/*footer css start*/

.footer-top {
    margin-top: 80px;
    background-color: #1440a8;
    padding: 30px 0px;
}

    .footer-top .whatsapp h5 {
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 0px;
    }

        .footer-top .whatsapp h5 span {
            color: var(--yellow);
            font-size: 28px;
            font-weight: 800;
        }

/*whatsapp css start*/

.btn-whatsapp-pulse {
    background: #25d366;
    color: white;
    font-size: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0;
    height: 0;
    padding: 35px;
    text-decoration: none;
    border-radius: 50%;
    animation: ripple-out 1.5s ease-in-out infinite;
    z-index: 99;
    transition: 0.3s;
}

    .btn-whatsapp-pulse:hover {
        rotate: 30deg;
        transition: 0.3s;
    }

    .btn-whatsapp-pulse:hover {
        color: #fff;
    }

@keyframes ripple-out {
    0% {
        box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
    }

    100% {
        box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
    }
}

/*whatsapp css end*/


/*admin-login css start*/

.modal {
    background-color: #00000061;
}

    .modal .menu-button {
        width: 100%;
    }

        .modal .menu-button .button-box a {
            width: 120px;
        }

    .modal .menu-button {
        justify-content: space-between;
        align-items: center;
    }

    .modal .fields input {
        padding: .75rem .75rem;
    }

    .modal .psw-forgot {
        text-align: right;
    }

    .modal .modal-dialog {
        max-width: 900px;
    }

    .modal .modal-body {
        padding: 0px !important;
    }

    .modal .modal-content {
        border: 1px solid #000;
    }

    .modal .modal-form {
        padding: 0px 30px;
    }

/*admin-login css end*/

footer {
    padding: 50px 0px;
    background-color: var(--blue);
    margin-top: 80px;
}


.ftr-links ul li {
    color: #ebebeb;
    margin-bottom: 5px;
}

    .ftr-links ul li a {
        font-size: 16px;
        transition: 0.3s;
        font-weight: 300;
        color: #fff;
    }

        .ftr-links ul li a:hover {
            color: var(--red);
        }

.ftr-links p {
    font-size: 18px;
    color: #fff;
    font-weight: 500;
}

.ftr-links ul li {
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    align-items: center;
}

    .ftr-links ul li i {
        margin-right: 10px;
        color: var(--yellow);
        transition: 0.3s;
        font-size: 12px;
    }

.ftr-heading {
    color: var(--yellow);
    font-size: 20px;
    font-weight: 600;
}

.ftr-social ul {
    margin-bottom: 0rem;
}

    .ftr-social ul li {
        display: inline-block;
        margin-right: 5px;
    }

        .ftr-social ul li a {
            display: block;
            width: 30px;
            height: 30px;
            color: #ebebeb;
            /* background: #fff; */
            text-align: center;
            line-height: 28px;
            border: 1px solid #ebebeb;
            border-radius: .15rem;
            transition: 0.3s;
            font-size: 14px;
        }

            .ftr-social ul li a:hover {
                background: #fff;
                border: 1px solid #fff;
                color: var(--blue);
            }

            .ftr-social ul li a i {
            }

.ftr-contact {
}

    .ftr-contact ul {
    }

        .ftr-contact ul li {
            font-size: 16px;
            display: flex;
            align-items: baseline;
            margin-bottom: 10px;
            font-weight: 300;
            color: #fff;
        }

            .ftr-contact ul li i {
                color: var(--yellow);
                margin-right: 8px;
                width: 15px;
                flex: none;
            }

            .ftr-contact ul li a {
                color: #fff;
            }

/*footer css end*/

/*footer-bottom css start*/

.footer_bottom {
    background-color: #0e0e0e;
    padding: 15px 0px;
}

    .footer_bottom .designed_by p {
        text-align: right;
        color: #525252;
    }

        .footer_bottom .designed_by p a {
            color: #525252;
        }

    .footer_bottom .copyroght {
        text-align: left;
    }

.copyroght, .designed_by {
}

    .copyroght p {
        margin-bottom: 0px;
        font-size: 14px;
        color: #fff;
    }

    .designed_by p, .designed_by p {
        margin-bottom: 0px;
    }

        .designed_by p, .designed_by p a {
            color: #8ea4d6;
            font-size: 14px;
        }


/*footer-bottom css end*/

.tracking-page {
    padding: 100px 0px;
}

/*Responsive css start*/

@media (max-width: 1600px) {
    .menu nav ul li {
        margin-left: 5px;
        margin-right: 5px;
    }

        .menu nav ul li a {
            font-size: 13px;
        }

    .navbar-brand img {
        /*width: 450px;*/
    }
}

@media (max-width: 1400px) {
    h1 {
        font-size: 40px;
    }

    h2 {
        font-size: 36px;
        font-weight: 800;
    }

    h3 {
        font-size: 32px;
        font-weight: 800;
    }

    .menu nav ul li {
        margin-left: 0px;
        margin-right: 0px;
    }

    .menu-button a {
        width: 160px;
    }

    .header-button:hover span {
        padding-right: 15px;
    }

    .header-button {
        font-size: 13px;
    }

    .menu nav ul li a {
        font-size: 14px;
    }

    .navbar-brand img {
        /*width: 400px;*/
    }

    .menu nav ul li .dropdown-menu li a {
        font-size: 12px;
    }

    .testimonial .testimonial-slider .test-user .user-image img {
        width: 350px;
    }

    .about-content h2 img {
        width: 320px;
    }
}

@media (max-width: 1200px) {
}


@media (min-width: 991px) {
    .menu nav ul li:hover .dropdown-menu {
        display: block;
        position: absolute;
    }

    .dropdown-menu {
        display: block;
        opacity: 0;
        visibility: hidden;
        transform: translateY(-20%);
        transition: all .5s;
    }

    .dropdown:hover .dropdown-menu {
        opacity: 1;
        visibility: visible;
        transform: translateY(0%);
    }
}


@media (max-width: 991px) {
    .navbar-brand img {
        width: 250px;
    }

    h1 {
        font-size: 36px;
    }

    .home-banner .tracking-app-banner {
        position: inherit;
        margin-top: 80px;
    }

    .home-banner .tracking-app-banner {
        bottom: 0px;
    }

    .tracking-app-banner .tracking-bx {
        box-shadow: 0 0 10px 7px rgb(0 0 0 / 3%);
        margin-bottom: 30px;
    }

    .home-banner .banner-content-slider .item {
        min-height: 247px;
    }

    .about-us {
        margin-top: 50px;
    }

        .about-us .about-image {
            text-align: center;
        }

        .about-us .about-content {
            margin-top: 30px;
        }

    p {
        text-align: justify;
    }

    .header-button {
        font-size: 14px;
    }

    .menu-button a {
        width: 150px;
    }

    .airliness-partner .air-partner .partner-image {
        width: 150px;
    }

    .our-advantage .sec-heading {
        margin-top: 30px;
    }

    .our-advantage .advantage-image {
        padding-right: 0px;
    }

    .rates .rates-image:before {
        display: none;
    }

    .rates .rates-image:after {
        display: none;
    }

    .rates .rates-image {
        padding: 0px 0px;
    }

    .rates .rates-content {
        padding-left: 0px;
        padding-top: 30px;
    }

    .copyroght p {
        text-align: center;
    }

    .footer_bottom .designed_by p {
        text-align: center;
        margin-bottom: 0px;
    }

    .ftr-links {
        margin-bottom: 20px;
    }

    .top-left-header ul li:first-child {
        display: none;
    }

    .tracking-image {
        display: none;
    }

    .our-activities .row:first-child {
        flex-direction: column-reverse;
    }

    .our-activities .activities-img {
        margin-bottom: 30px;
    }

    .faq .contact-form {
        margin-left: 0px;
    }

    .ftr-social {
        margin-bottom: 30px;
    }

    .menu {
        top: 0px !important;
    }

    .our-services .service-box .service-content {
        opacity: 1;
    }

    .poup-image {
        display: none;
    }

    .modal .modal-form {
        padding: 50px 50px;
    }
}

@media (min-width: 767px) {
    .phone-track {
        display: none;
    }
}

@media (max-width: 767px) {
    .navbar-brand img {
        width: 200px;
    }

    .footer-top .whatsapp-button {
        text-align: center;
        margin-top: 30px;
    }

    .whatsapp {
        text-align: center;
    }

    .btn-whatsapp-pulse {
        margin: 0 auto;
    }

    h1 {
        font-size: 28px;
        font-weight: 700;
    }

    p {
        font-size: 14px;
    }

    .home-banner .banner-content-slider .item {
        min-height: 235px;
    }

    .banner-content-slider {
        display: none;
    }

    h2 {
        font-size: 24px;
        font-weight: 800;
    }

    h4 {
        font-size: 16px;
    }

    .tracking-app-banner .track-input-field .fields {
        width: 100%;
    }

    .phone-track {
        margin-top: 20px;
    }

    h3 {
        font-size: 24px;
    }

    .ftr-last {
        margin-bottom: 0px;
    }

    .our-advantage .advantage-box {
        padding: 15px;
    }

    .tracking-app-banner .tracking-bx {
        padding: 15px;
    }

    .faq .contact-form {
        padding: 15px;
    }

    .menu .menu-button .button-box .header-button2 {
        display: none;
    }

    .menu-button a {
        width: 180px;
    }

    .footer_bottom .copyroght {
        margin-bottom: 10px;
    }

    .our-services .service-box .service-image {
        height: 240px;
    }

    .modal .modal-form {
        padding: 20px 20px;
    }
}

@media (max-width: 575px) {
    .banner-content-slider {
        display: none;
    }

    .airliness-partner .air-partner .partner-image {
        background-size: 120px;
        width: 120px;
        height: 50px;
    }

    .ftr-social {
        margin-bottom: 20px;
    }

    .footer-top .whatsapp h5 {
        font-size: 18px;
        font-weight: 600;
    }

        .footer-top .whatsapp h5 span {
            font-size: 22px;
            font-weight: 700;
        }

    .about-us .delivery-bar div {
        margin-right: 15px;
    }

    .top-left-header ul li:last-child {
        display: none;
    }

    .menu .menu-button .button-box .header-button3 {
        display: none;
    }

    .menu .menu-button .button-box {
        margin-right: 0px;
    }

    .menu .menu-button a {
        width: 130px;
        font-size: 10px;
    }

    .navbar-brand img {
        width: 160px;
    }

    .about-us {
        margin-top: 50px;
    }

    h3:before {
        width: 100px;
        height: 2px;
    }

    h2:before {
        width: 100px;
        height: 2px;
    }

    .fields input {
        padding: .75rem .75rem;
    }

    .about-content h2 img {
        width: 186px;
    }
}

/*Responsive css end*/



.track-top {
    padding: 5px 15px;
    background: #4e41ff;
    position: relative;
}

    .track-top button {
        text-decoration: none;
        background: #ed1c24;
        color: #fff;
        padding: 5px 12px;
        border-radius: 25px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1px;
        display: inline-block;
    }

    .track-top ul {
        padding-left: 0px;
        display: inline-block;
        margin-bottom: 0px;
    }

        .track-top ul li {
            list-style: none;
            display: inline-block;
            font-size: 14px;
            font-weight: 500;
            margin-left: 20px;
            color: #fff;
        }

            .track-top ul li span.bold-text {
                font-weight: 600;
                color: #fff;
            }

    .track-top .toggle-point {
        display: inline-block;
        float: right;
        cursor: pointer;
    }

.packet-status h6, .packet-history h6 {
    margin-bottom: 5px;
    margin-top: 10px;
}

.packet-status table tr td, .packet-status table tr th, .packet-history table tr td, .packet-history table tr th {
    padding: 5px;
    font-size: 14px;
}

.packet-status table tr td {
    width: auto;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.7);
}

.tracking-details {
    position: relative;
    padding: 20px 0;
    list-style: none;
    max-width: 1200px;
    margin: 0 auto;
}

    .tracking-details:before {
        content: " ";
        position: absolute;
        top: 40px;
        bottom: 70px;
        left: 23%;
        width: 3px;
        margin-left: -1.5px;
        background-color: #eee;
    }

    .tracking-details > li {
        position: relative;
        margin-bottom: 20px;
    }

        .tracking-details > li > .tracking-date {
            float: left;
            text-align: right;
            position: relative;
            width: 20%;
            padding: 20px 20px 20px 0px;
        }

.tracking-title {
    margin-top: 0;
    color: inherit;
    font-weight: 400;
    font-size: 14px;
}

.tracking-details > li > .tracking-badge {
    z-index: 9;
    position: absolute;
    top: 16px;
    left: 23%;
    width: 40px;
    height: 40px;
    margin-left: -20px;
    border-radius: 50%;
    text-align: center;
    font-size: 1.2em;
    line-height: 40px;
    color: #fff;
    overflow: hidden;
    background-color: #4c5667;
}

.tracking-badge.success {
    background-color: #4caf50 !important;
}

.tracking-details .tracking-panel {
    float: right;
    position: relative;
    width: 75%;
    padding: 15px 25px;
}

.tracking-details > li:after {
    content: " ";
    display: table;
}

.tracking-details > li:before {
    content: " ";
    display: table;
}

.tracking-details > li:after {
    clear: both;
}

.tracking-section {
    background: #fff !important;
}

#track-data {
    padding: 30px 20px;
}

span.toggle-point i {
    color: #fff;
}

.pincode {
    width: 180px;
}

.pincode-modal .modal-body {
    padding: 2rem 1rem !important;
}


.country-city .industry-icon {
    background-color: transparent !important;
}

a.header-button.border-btn {
    background: transparent;
    color: var(--yellow);
    border: 2px solid var(--yellow);
    padding: 10px 0px;
}

.get-rate-form-section {
    background: #fff;
    padding: 30px;
    border-radius: 8px;
}

section.get-rate-section {
    padding: 80px 0px;
}

.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: var(--yellow) !important;
    outline: 0;
    box-shadow: none !important;
    border: 1px solid var(--yellow) !important;
}



.rate-table {
    background: #fff;
    padding: 30px;
    border-radius: 8px;
}
.rate-form .form-select {
    border: 1px solid #a3a3a3 !important;
    font-size: 15px !important;
    font-family: 'Inter';
    border-radius: 30px;
    line-height: 1.5 !important;
    padding-left: 20px !important;
}

.form-select:focus {
    color: #212529;
    background-color: #fff;
    border-color: var(--yellow) !important;
    outline: 0;
    box-shadow: none !important;
}
.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input:focus {
    border-color: var(--yellow) !important;
}
.rate-form input {
    border: 1px solid #a3a3a3 !important;
    font-size: 15px !important;
    font-family: 'Inter';
    border-radius: 30px;
}

.rate-form .input-group-text {
    color: #4e41ff;
    border: 1px solid #a3a3a3 !important;
    background-color: #e4e7ea;
    border-radius: 30px;
}

.MuiAutocomplete-root fieldset {

    
}

.MuiAutocomplete-root .Mui-focused fieldset {
    border-color: var(--orange) !important;
    background-color: #ffffff17 !important;
}

.MuiAutocomplete-root .Mui-focused input {
    background-color: transparent !important;
}
.Mui-focused {
    border-color: var(--yellow) !important;
}


.form-select {
    border-radius: 3px;
    line-height: 1.1 !important;
    background-color: transparent !important;
    border-color: var(--border-light) !important;
    color: var(--text) !important;
    background-image: var(--bs-form-select-img) !important;
}

.fields {
    margin-bottom: 10px;
    line-height: 16px;
}

    .fields input, select, textarea {
        font-size: 12px !important;
        font-weight: 400;
        padding: 6px 0.50rem !important;
        text-decoration: none;
        outline: none !important;
        outline-width: 0;
        border-radius: 5px;
        transition: .3s;
        background-color: transparent;
        border-color: var(--border-light);
        overflow: hidden;
    }
.muiwidthfull .MuiAutocomplete-root {
    width: 100% !important;
}
.test {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}
.MuiAutocomplete-root {
    border: 1px solid #a3a3a3;
    border-radius: 30px;
    width: 88%;
}
.MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiAutocomplete-inputRoot css-1bp1ao6 {
    padding: 3px !important;
}
.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 3px 18px !important;
    border: 0 !important;
    height: 30px;
}
    .MuiAutocomplete-root .MuiOutlinedInput-root {
         padding: 0px !important; 
    }
.MuiOutlinedInput-notchedOutline {
    border: none !important;
}
.MuiInputBase-input-MuiOutlinedInput-input {
    border-radius: 4px !important;
}
.MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 0.5px 0px 0.5px 5px;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    .MuiAutocomplete-root {
        width: 92%;
         border: 1px solid #ccc; 
         border-radius: 9px; 
    }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
    .MuiAutocomplete-root {
        width: 89%;
         border: 1px solid #ccc; 
         border-radius: 9px; 
    }
}
@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .MuiAutocomplete-root {
        width: 82%;
    }
}
@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .MuiAutocomplete-root {
        width: 82%;
    }
}
@media screen and (min-width: 526px) and (max-width: 767px) {
    .MuiAutocomplete-root {
        width: 86%;
       
    }
}
@media screen and (min-width: 300px) and (max-width: 525px) {
    .MuiAutocomplete-root {
        width: 87%;
    }
}
ul.terms-list li {
    list-style: auto;
    margin-bottom: 10px;
    font-size:8px;
}

.terms-title {
    margin-left: -15px;
}

@media (max-width: 767px) {
    .terms-title {
        margin-left: 0px;
    }

    .menu-button {
        flex-direction: column;
    }

    a.header-button.border-btn {
        margin-bottom: 10px;
    }
}
.banned-list {
    list-style-type: disc !important;
    margin-left: 20px;
}

    .banned-list li {
        margin-bottom: 10px;
        list-style: disc;
        font-size: 13px;
    }
.rate-form-title h2 {
    font-size: 33px;
}
.back_to_home_btn{
    text-align:right;
    margin-bottom:20px;
}

    .back_to_home_btn .header-button span:after {
        content:'';
        position: absolute;
        opacity: 0;
        top: -7px;
        left: -20px;
        transition: 500ms;
        font-size: 20px;
    }
.get-rate-point {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
}

    .get-rate-point p {
        margin-bottom: 0px;
    }

.rate-form-title h2 {
    font-size: 32px;
    font-weight: 700;
}
.coming-soon {
    padding: 120px 0px;
}

.coming-content h4 {
    font-size: 22px;
}

.mymodaltable th {
    padding: 3px 10px !important;
}
.mymodaltablebody .form-control {
    /*padding: 3px 10px !important;*/
    margin-bottom: 0px !important;
}
.international {
    padding: .375rem .98rem;
}

.custom-loader {
    width: 25px;
    height: 6px;
    background: radial-gradient(circle closest-side, #ffffff 90%, #0000) 0 0/33% 100% space;
    clip-path: inset(0 100% 0 0);
    animation: d1 1s steps(4) infinite;
}

@keyframes d1 {
    to {
        clip-path: inset(0 -34% 0 0);
    }
}

.pickupdetails_Input {
    padding: 3px 10px;
}

/*fileuplaoder*/
.file-uploader {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    min-width: 100% !important;
    max-width: 508px !important;
    height: 40px !important;
    margin-top: 0px !important;
    border: 2px dotted #4E2D87 !important;
    padding: 3px 5px !important;
    border-radius: 10px;
    cursor: pointer;
    -webkit-box-flex: 0;
    flex-grow: 0;
}

    .file-uploader > svg {
        width: 20px !important;
        height: 20px !important;
    }

    .file-uploader span {
        margin-left: 5px;
        /*font-size: 10px !important;*/
        color: black !important;
    }


    .file-uploader .file-children {
        display: flex !important;
        justify-content: space-between !important;
        width: 100% !important;
    }

        /*.file-uploader .file-types {
        color: var(--text) !important;
    }*/

        .file-uploader .file-children .sc-fqkvVR span:first-child {
            margin-left: 5px;
            margin-right: 5px;
            /*font-size: 10px !important;*/
            color: black !important;
            text-decoration: underline;
        }

.fullscreen-image {
    width: -webkit-fill-available;
    height: 550px;
}

.fullscreen-image-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
 